import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';

@Component({
  selector: 'app-defaultdashboard-mailshotdetail',
  templateUrl: './defaultdashboard-mailshotdetail.component.html',
  styleUrl: './defaultdashboard-mailshotdetail.component.scss'
})
export class DefaultdashboardMailshotdetailComponent implements OnInit {
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService
  ){}

  ngOnInit(): void {
    
  }
}
