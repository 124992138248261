import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ConfirmationService } from 'primeng/api';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-echobot-interface',
  templateUrl: './echobot-interface.component.html',
  styleUrl: './echobot-interface.component.scss'
})

export class EchobotInterfaceComponent implements OnInit {
  refId = null;
  interfaceResults = null;
  loadingData = true;
  resultEntity = 1;
  previewDataArray = null;
  enrichmentPreviewValues = [];
  enrichmentPreviewTableValues = [];
  seletedInterfaceResultItem = null;

  accountDataExternalDatasourceId = null;
  previewExternalDatasourceId = null;
  enrichExternalDatasourceId = null;
  currentValues = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {    
    this.refId = this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceRefId']; 

    if (this.resultEntity == 1) {
      this.accountDataExternalDatasourceId = 979;
      this.previewExternalDatasourceId = 981;
      this.enrichExternalDatasourceId = 982;  
    }

    this.getAccountDataFromInterface();     

    if (this.applicationInfoService.applicationSettings['echobotDetailPreviewItemsEntity'.concat(this.resultEntity.toString())]) {
      this.enrichmentPreviewValues = this.applicationInfoService.applicationSettings['echobotDetailPreviewItemsEntity'
        .concat(this.resultEntity.toString())].split(';');
      this.enrichmentPreviewValues.forEach(item => {
        const itemDetails = item.split('-');
        itemDetails.push(true);
        this.enrichmentPreviewTableValues.push(itemDetails);
      });
    }
  }

  getAccountDataFromInterface() {
    this.loadingData = true;
    this.interfaceResults = [];
    this.externaldatasourceService.executeExternalDataSource(this.accountDataExternalDatasourceId, [this.refId])
    .then(getDataFromInterfaceResult => {
      getDataFromInterfaceResult.sort((a, b) => a.matchScore > b.matchScore ? -1 : 1);
      this.interfaceResults = getDataFromInterfaceResult;
      this.loadingData = false;
    }).catch(() => { this.loadingData = false; });
  }

  enrichAccountData(interfaceResultItem) {
    this.loaderService.display(true);

    // echobotResult
    const echobotResultArray = this.previewDataArray;
    this.enrichmentPreviewTableValues.forEach(enrichmentPreviewTableValue => {

    });
    let echobotResultArrayJson = JSON.stringify(echobotResultArray);
    Object.keys(this.previewDataArray).forEach(keyValue =>  {      
      echobotResultArrayJson = echobotResultArrayJson.replace('"'.concat(keyValue, '"'), keyValue);
    });

    // fieldArray
    const fieldsArray = [];
    this.enrichmentPreviewTableValues.forEach(enrichmentPreviewTableValue => {
      if (enrichmentPreviewTableValue[3] == true) {
        fieldsArray.push({
          echobotField: enrichmentPreviewTableValue[0],
          accountField: enrichmentPreviewTableValue[1]
        });  
      }
    });
    let fieldsArrayJson = JSON.stringify(fieldsArray);
    Object.keys(fieldsArrayJson).forEach(() =>  {      
      fieldsArrayJson = fieldsArrayJson.replace('"'.concat('echobotField', '"'), 'echobotField');
      fieldsArrayJson = fieldsArrayJson.replace('"'.concat('accountField', '"'), 'accountField');
    });

    this.externaldatasourceService.executeExternalDataSource(this.enrichExternalDatasourceId, [
      this.refId, 
      echobotResultArrayJson,
      fieldsArrayJson
    ]).then(enrichAccountDataResult => {
        this.messagingService.showDefaultSuccess('', this.axivasTranslateService.getTranslationTextForToken('DataInterface.Label.EnrichmentSuccessfull'))
        this.loaderService.display(false);
        switch(this.resultEntity) {
          case 1:
            if (this.applicationInfoService.currentAccount) {
              if (this.applicationInfoService.currentAccount.id == this.refId) {
                this.enrichmentPreviewTableValues.forEach(valueItem => {
                  if (valueItem[3] == true) {
                    this.applicationInfoService.entities.Item(this.resultEntity.toString()).data[valueItem[1]] = 
                      this.previewDataArray[valueItem[0]];
                  }
                });
                this.eventService.updateEntityDataPropertyEvent.emit(new JJEvent(this.resultEntity.toString()));
              }
            }
            break;
          case 4:
            if (this.applicationInfoService.currentContact) {
              if (this.applicationInfoService.currentContact.id == this.refId) {

              }
            }
            break;
        }
    }).catch(() => { this.loaderService.display(false); });
  }

  previewData(interfaceResultItem) {
    this.seletedInterfaceResultItem = interfaceResultItem;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(this.previewExternalDatasourceId, [interfaceResultItem.id])
    .then(previewDataResult => {
      this.previewDataArray = previewDataResult;
      this.loaderService.display(false);
      this.getCurrentValues();
    }).catch(() => { this.loaderService.display(false); });
  }

  enrichData(interfaceResultItem) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichPrompt.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichPrompt.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.previewData(interfaceResultItem);
      },
      reject: () => { }
    });
  }
  
  getCurrentValues() {
    let externalDatasourceId = 844;
    let values = '';        
    this.enrichmentPreviewTableValues.forEach(item => {
      values = values.concat(' ', item[1]);
    });    
    console.warn('previewDataArray', this.enrichmentPreviewTableValues, values)
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.refId,
      values
    ]).then(getCurrentValuesResult => {
      this.currentValues = getCurrentValuesResult;
    });
  }
}
