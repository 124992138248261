<ng-container>

    <div [ngClass]="{
        'auto-height': controlDefinition.controlType == controlType.EntityTable && controlDefinition.height != '' && controlDefinition.height != null,
        'inline': isinline,
        'isRequiredNewDesign': isRequired,        
        'customUniversalElement__designlight': applicationInfoService.designerMode && designMode == 2,
        'hideInDom': !controlService.checkIfControlIsVisible(controlDefinition),
        'hoverId': controlDefinition.logicalControlId == applicationInfoService.controlDesignerHoverId
    }" [class]="getMainClass()" *ngIf="controlDefinition != null" [ngStyle]="setMainControlStyle(true)"
        [id]="getControlId()" [style.padding-left.px]="getLeftPadding()" [tippy]="getTitle()">

        <div class="universalControl__wrapper" [ngClass]="{ 
            flexColumn: controlDefinition?.isFieldActivatable && applicationInfoService.designerMode != true,
            everleadCustomControlBorder:    controlDefinition?.controlType == controlType.Textbox ||
                                            controlDefinition?.controlType == controlType.Textarea ||
                                            controlDefinition?.controlType == controlType.Dropdown || 
                                            controlDefinition?.controlType == controlType.Research ||
                                            controlDefinition?.controlType == controlType.Dial ||
                                            controlDefinition?.controlType == controlType.Website   
        }">
            <div class="universalControl__item universalControl__activate" *ngIf="controlDefinition?.isFieldActivatable && applicationInfoService.designerMode != true">
                <div class="isActivatable fingerCursor" *ngIf="controlDefinition?.isFieldActivatable" (click)="toggleActivatedForQueries()">
                    <i class="fas fa-check-circle everleadGreen" style="width: 10px; margin-right: 10px;"
                        *ngIf="isControlActivatedForQuerys"></i>
                    <i class="fas fa-times-circle everleadCoral" style="width: 10px; margin-right: 10px;"
                        *ngIf="!isControlActivatedForQuerys"></i>
                    <label class="universalControl__activate__label fingerCursor" *ngIf="!isControlActivatedForQuerys">{{ 'Control.Label.NotActivatedForQuerys' | texttransform }}</label>
                    <label class="universalControl__activate__label fingerCursor" *ngIf="isControlActivatedForQuerys">{{ 'Control.Label.ActivatedForQuerys' | texttransform }}</label>
                </div>
            </div>
            <div class="universalControl__item universalControl__control universalComponent everleadBlueColor" [ngClass]="{
                activatePadding: controlDefinition?.isFieldActivatable && applicationInfoService.designerMode != true
            }" [id]="getControlSubId()">
                <app-custom-label *ngIf="controlDefinition?.controlType == controlType.Label"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-label>

                <app-custom-resultpreview *ngIf="controlDefinition?.controlType == controlType.ResultPreview"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-resultpreview>

                <app-custom-questionnaire *ngIf="controlDefinition?.controlType == controlType.Questionnaire"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource">
                </app-custom-questionnaire>

                <app-custom-subresult *ngIf="controlDefinition?.controlType == controlType.Subresult"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource">
                </app-custom-subresult>

                <app-custom-button *ngIf="controlDefinition?.controlType == controlType.Button"
                    [controlDefinition]="controlDefinition">
                </app-custom-button>

                <app-custom-horizontal-rule *ngIf="controlDefinition?.controlType == controlType.HorizontalRule"
                    [controlDefinition]="controlDefinition">
                </app-custom-horizontal-rule>

                <app-custom-spacer *ngIf="controlDefinition?.controlType == controlType.SpacerItem"
                    [controlDefinition]="controlDefinition">
                </app-custom-spacer>

                <app-custom-copytoclipboard-button *ngIf="controlDefinition?.controlType == controlType.CopyToClipboard"
                    [controlDefinition]="controlDefinition">
                </app-custom-copytoclipboard-button>

                <app-custom-communication-guideline
                    *ngIf="controlDefinition?.controlType == controlType.CommunicationControl"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource">
                </app-custom-communication-guideline>

                <app-custom-mattable *ngIf="controlDefinition?.controlType == controlType.MatTable && controlDefinition.additionalSetting1 != 'entityTable'"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource">
                </app-custom-mattable>

                <app-custom-mattable *ngIf="controlDefinition?.controlType == controlType.MultiList  && controlDefinition.additionalSetting1 != 'entityTable'"
                    [controlDefinition]="controlDefinition" isMultiList=true [externalDataSource]="externalDataSource">
                </app-custom-mattable>

                <app-custom-checkbox *ngIf="controlDefinition?.controlType == controlType.CheckBox"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-checkbox>

                <app-custom-optinoptout *ngIf="controlDefinition?.controlType == controlType.OptInOptOut"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-optinoptout>

                <app-custom-compactlist *ngIf="controlDefinition?.controlType == controlType.CompactList"
                    [additionalValues]="additionalvalues" [controlDefinition]="controlDefinition"
                    [externalDataSource]="externalDataSource" [entity]="entity"></app-custom-compactlist>

                <app-custom-website *ngIf="controlDefinition?.controlType == controlType.Website"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-website>

                <app-custom-dial *ngIf="controlDefinition?.controlType == controlType.Dial"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-dial>

                <app-custom-menubar *ngIf="controlDefinition?.controlType == controlType.MenuBar"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-menubar>

                <app-custom-research *ngIf="controlDefinition?.controlType == controlType.Research"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-research>

                <app-custom-projectoverview *ngIf="controlDefinition?.controlType == controlType.ProjectOverview"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-projectoverview>

                <app-custom-powerbutton *ngIf="controlDefinition.controlType == controlType.PowerButton"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-powerbutton>

                <app-custom-register *ngIf="controlDefinition?.controlType == controlType.Register"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-register>

                <app-custom-dropdown *ngIf="controlDefinition.controlType == controlType.Dropdown"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource"
                    [entityMemberlookupTable]="entityMemberlookupTable" [entity]="entity">
                </app-custom-dropdown>

                <app-custom-textarea
                    *ngIf=" controlDefinition?.controlType == controlType.Textarea ||
                            controlDefinition?.controlType == controlType.AiTextAreaAccount ||
                            controlDefinition?.controlType == controlType.AiTextAreaContact ||
                            controlDefinition?.controlType == controlType.AiTextAreaTask
                    "
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-textarea>

                <app-custom-informationpanel *ngIf="controlDefinition?.controlType == controlType.Information"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-informationpanel>

                <app-custom-textbox *ngIf="controlDefinition?.controlType == controlType.Textbox" style="width: 100%"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-textbox>

                <app-custom-datepicker *ngIf="controlDefinition.controlType == controlType.Calendar"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-datepicker>

                <app-custom-listview *ngIf="controlDefinition?.controlType == controlType.List"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-listview>

                <app-custom-leadscore *ngIf="controlDefinition?.controlType == controlType.Leadscore"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource" [entity]="entity">
                </app-custom-leadscore>


                <app-boldbi-dashboard *ngIf="controlDefinition?.controlType == controlType.BoldBiDashBoard"
                    [controlDefinition]="controlDefinition">
                </app-boldbi-dashboard>

                <app-prospect-detail *ngIf="controlDefinition?.controlType == controlType.ProspectDetail"
                    [useContactProspect]=true>
                </app-prospect-detail>

                <app-custom-radio *ngIf="controlDefinition.controlType == controlType.Radio"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource"
                    [entityMemberlookupTable]="entityMemberlookupTable" [entity]="entity"></app-custom-radio>

                <app-custom-emaileditor *ngIf="controlDefinition.controlType == controlType.EMailEditor"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource"
                    [entityMemberlookupTable]="entityMemberlookupTable" [entity]="entity"></app-custom-emaileditor>

                <app-custom-email *ngIf="controlDefinition.controlType == controlType.EMail"
                    [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource"
                    [entityMemberlookupTable]="entityMemberlookupTable" [entity]="entity"></app-custom-email>

                <app-custom-multilist *ngIf="controlDefinition.controlType == controlType.MultiListSelect"
                    [controlDefinition]="controlDefinition" [entityMemberlookupTable]="entityMemberlookupTable"
                    [entity]="entity"></app-custom-multilist>
                <app-compact-tasklist *ngIf="controlDefinition.controlType == controlType.CompactTaskList"
                    [controlDefinition]="controlDefinition"></app-compact-tasklist>
                <app-custom-eventinfo *ngIf="controlDefinition.controlType == controlType.EventInfo"
                    [controlDefinition]="controlDefinition"></app-custom-eventinfo>
                <!-- <app-custom-contactheader *ngIf="controlDefinition.controlType == controlType.ContactHeader"
                    [entity]="entity" [controlDefinition]="controlDefinition"></app-custom-contactheader> -->
                <app-custom-mynextfollowup *ngIf="controlDefinition.controlType == controlType.MyNextFollowUp"
                    [entity]="entity" [controlDefinition]="controlDefinition"></app-custom-mynextfollowup>
                <app-custom-accordeon *ngIf="controlDefinition.controlType == controlType.Accordeon"
                    [controlDefinition]="controlDefinition"></app-custom-accordeon>

                <app-custom-dashoardcontrol *ngIf="controlDefinition.controlType == controlType.DashboardControl"
                    [controlDefinition]="controlDefinition"></app-custom-dashoardcontrol>

                <app-custom-accountowner *ngIf="controlDefinition.controlType == controlType.AccountOwner"
                    [controlDefinition]="controlDefinition"></app-custom-accountowner>

                <app-custom-calculateleadstate *ngIf="controlDefinition.controlType == controlType.CustomCalculatedLeadstate"
                    [controlDefinition]="controlDefinition"></app-custom-calculateleadstate>

                <app-entitytable *ngIf="controlDefinition.controlType == controlType.EntityTable || controlDefinition.additionalSetting1 == 'entityTable'"
                    [controlDefinition]="controlDefinition"></app-entitytable>

                <app-questionaire-control *ngIf="controlDefinition.controlType == controlType.ProjectQuestionaire"
                    [controlDefinition]="controlDefinition"></app-questionaire-control>
                
                <app-custom-optinoverview *ngIf="controlDefinition.controlType == controlType.OptInOverview"
                    [controlDefinition]="controlDefinition"></app-custom-optinoverview>
                
                <app-callprojectbooking *ngIf="controlDefinition.controlType == controlType.CallProjectBooking"
                    [controlDefinition]="controlDefinition"></app-callprojectbooking>

                <!-- <app-custom-contactleadstateoverview *ngIf="controlDefinition.controlType == controlType.ContactLeadstateOverview"
                    [controlDefinition]="controlDefinition"></app-custom-contactleadstateoverview> -->

                <app-custom-entityfileupload *ngIf="controlDefinition.controlType == controlType.EntityFileUpload"
                    [controlDefinition]="controlDefinition"></app-custom-entityfileupload>

                <app-custom-activity-list *ngIf="controlDefinition.controlType == controlType.ActivityTimeline"></app-custom-activity-list>                    
                <app-custom-micro-campaigns *ngIf="controlDefinition.controlType == controlType.MicroCampaigns"></app-custom-micro-campaigns>
                <app-leadscoring-detail *ngIf="controlDefinition.controlType == controlType.LeadscoringDetail"></app-leadscoring-detail>

                <app-contact-protection *ngIf="controlDefinition.controlType == controlType.ContactProtection"></app-contact-protection>
                <app-custom-account-overview *ngIf="controlDefinition.controlType == controlType.AccountOverview"></app-custom-account-overview>
                <app-custom-aipromptresult *ngIf="controlDefinition.controlType == controlType.BattleCard"></app-custom-aipromptresult>
            </div>
        </div>
        <div class="customUniversalElement__designer" *ngIf="applicationInfoService.designerMode && designMode == 2" 
            [tippy]="'UIControlSettings.Label.Designer' | texttransform">
            <i class="fas fa-edit" (click)="editControlLight()"></i>
        </div>
    </div>

</ng-container>
