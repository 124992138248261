<div class="echobotInterfaceWrapper">
    <span *ngIf="loadingData==true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
    
    <div class="echobotInterfaceResult" *ngIf="loadingData==false && previewDataArray != null">
        <!-- Preview -->
        <div class="echobotInterfaceResultInfo" *ngIf="interfaceResults?.length > 0">
            {{ 'DataInterface.Label.TheFollowingDataCanBeEnriched' | texttransform }}
        </div>
         <div class="enrichtmentPreviewItemList">
            <div class="enrichtmentPreviewItemListItem enrichtmentPreviewItemListItem__header">
                <div class="enrichtmentPreviewItemListItem__enrich"></div>
                <div class="enrichtmentPreviewItemListItem__name">
                    {{ 'DataInterface.Label.DataField' | texttransform }}
                </div>
                <div class="enrichtmentPreviewItemListItem__value">
                    {{ 'DataInterface.Label.FromDataInterface' | texttransform }}
                </div>
                <div class="enrichtmentPreviewItemListItem__value">
                    {{ 'DataInterface.Label.CurrentValue' | texttransform }}
                </div>
            </div>
            <div *ngFor="let enrichmentPreviewValue of enrichmentPreviewTableValues" class="enrichtmentPreviewItemListItem everleadTableRow">
                <div class="enrichtmentPreviewItemListItem__enrich">
                    <input type="checkbox" [(ngModel)]="enrichmentPreviewValue[3]">
                </div>
                <div class="enrichtmentPreviewItemListItem__name">{{ enrichmentPreviewValue[2] | texttransform }}</div>
                <div class="enrichtmentPreviewItemListItem__value">{{ previewDataArray[enrichmentPreviewValue[0]] }}</div>
                <div class="enrichtmentPreviewItemListItem__value">
                    <span *ngIf="currentValues != null">{{ currentValues[enrichmentPreviewValue[1]] }}</span>
                </div>
            </div>
         </div>
         <div class="enrichtmentPreviewItemButtons">
            <app-main-button (onClick)="enrichAccountData()" label="DataInterface.Label.ExecuteEnrichtment"></app-main-button>
         </div>
    </div>

    <!-- Start -->
    <div class="echobotInterfaceResult" *ngIf="loadingData==false && previewDataArray == null">
        <app-layout header="DataInterface.Label.Results">
            <div class="echobotInterfaceResultInfo" *ngIf="interfaceResults?.length > 0">
                {{ 'DataInterface.Label.SelectResultItemToEnrich' | texttransform }}
            </div>
            <div class="echobotInformationLine__wrapper" *ngIf="resultEntity == 1">
                <div class="echobotInformationLine">
                    <div class="echobotInformationLine__value">{{ 'DataInterface.Label.MatchScore' | texttransform }}</div>
                    <div class="echobotInformationLine__description">{{ 'DataInterface.Label.MatchScoreDesc' | texttransform }}</div>
                </div>
                <div class="echobotInformationLine">
                    <div class="echobotInformationLine__value">{{ 'DataInterface.Label.DataQuality' | texttransform }}</div>
                    <div class="echobotInformationLine__description">{{ 'DataInterface.Label.DataQualityDesc' | texttransform }}</div>
                </div>
            </div>
            <div *ngIf="interfaceResults?.length == 0" class="echobotInterfaceResultNoData">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>
            <div class="echobotInterfaceResultItemlistItem echobotInterfaceResultItemlistItemHeader" *ngIf="interfaceResults?.length > 0">
                <div class="echobotInterfaceResultItemlistItem__name">{{ 'DataInterface.Label.Name' | texttransform }}</div>
                <div class="echobotInterfaceResultItemlistItem__details">{{ 'DataInterface.Label.Details' | texttransform }}</div>
                <div class="echobotInterfaceResultItemlistItem__completenessScore">{{ 'DataInterface.Label.DataQuality' | texttransform }}</div>
                <div class="echobotInterfaceResultItemlistItem__matchScore">{{ 'DataInterface.Label.MatchScore' | texttransform }}</div>                
            </div>
            <div class="echobotInterfaceResultItemlist">
                <div class="echobotInterfaceResultItemlistItem everleadTableRow fingerCursor" *ngFor="let interfaceResultItem of interfaceResults"
                    (click)="enrichData(interfaceResultItem)">
                    <div class="echobotInterfaceResultItemlistItem__name" *ngIf="resultEntity == 1">
                        {{ interfaceResultItem.companyName }}
                    </div>
                    <div class="echobotInterfaceResultItemlistItem__details" *ngIf="resultEntity == 1">                        
                        <div *ngIf="interfaceResultItem.zipcode">{{ interfaceResultItem.zipcode }}</div>
                        <div *ngIf="interfaceResultItem.city">{{ interfaceResultItem.city }}</div>
                        <div *ngIf="interfaceResultItem.street">{{ interfaceResultItem.street }}</div>
                        <div *ngIf="interfaceResultItem.email">{{ interfaceResultItem.email }}</div>
                    </div>
                    <div class="echobotInterfaceResultItemlistItem__completenessScore">
                        <app-bar-leadscore [value]="interfaceResultItem.completenessScore"></app-bar-leadscore>
                    </div>

                    <div class="echobotInterfaceResultItemlistItem__matchScore">
                        <app-bar-leadscore [value]="interfaceResultItem.matchScore"></app-bar-leadscore>
                    </div>
                </div>
            </div>
        </app-layout>
    </div>    
</div>
