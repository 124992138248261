import { Injectable } from '@angular/core';
import { GraphQLService, ApolloMethod } from '../../shared/service/graphql.service';
import { ApplicationInfoService, VariableType } from '../../core/application/application-info.service';
import { EventService } from './event.service';
import { LoaderService } from '../../shared/service/loader-service';
import { CommonService } from './common.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { Dictionary } from 'app/core/dictionary';
import { EntityService } from './entity.service';
import { isNullOrUndefined, isUndefined } from 'util';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { environment } from 'environments/environment';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ExternaldatasourceService } from './externaldatasource.service';

export class DragDropelements {
  itemDictAdded: Dictionary<any> = new Dictionary<any>();
  itemDictRemoved: Dictionary<any> = new Dictionary<any>();
  itemDictInitial: Dictionary<any> = new Dictionary<any>();
  filterString = '';
  items: any[] = [];
  itemsUI: any[] = [];
  header = '';
}

export enum ControlType {
  Container = 0,
  Button = 1,
  Textbox = 2,
  CheckBox = 3,
  List = 4,
  Calendar = 5,
  Dropdown = 6,
  Information = 7,
  Label = 8,
  Dial = 9,
  MenuItem = 10,
  Textarea = 11,
  Register = 12,
  Website = 13,
  Research = 14,
  PowerButton = 15,
  CompactList = 16,
  SpacerItem = 17,
  ResultPreview = 18,
  HorizontalRule = 19,
  Questionnaire = 20,
  MenuBar = 21,
  Subresult = 22,
  Image = 23,
  CommunicationControl = 24,
  ProjectOverview = 25,
  CopyToClipboard = 26,
  MatTable = 27,
  Thermometer = 28,
  Leadscore = 29,
  MultiList = 30,
  OptInOptOut = 31,
  BoldBiDashBoard = 32,
  ProspectDetail = 33,
  Palette = 35,
  Radio = 34,
  EMailEditor = 36,
  CompactTaskList = 37,
  EMail = 38,
  ContactHeader = 39,
  EventInfo = 40 ,
  MultiListSelect = 41 ,
  MyNextFollowUp = 42,
  Accordeon = 43,
  EntityTable = 44,
  DashboardControl = 45,
  AccountOwner = 46,
  AiTextAreaAccount = 47,
  AiTextAreaContact = 48,
  AiTextAreaTask = 49 ,
  CustomCalculatedLeadstate = 50,
  ProjectQuestionaire = 51,
  OptInOverview = 52,
  PaletteChild = 53,
  CallProjectBooking = 54,
  ContactLeadstateOverview = 55,
  EntityFileUpload = 56,
  ActivityTimeline = 57,
  MicroCampaigns = 58,
  LeadscoringDetail = 59,
  ContactProtection = 60,
  AccountOverview = 61,
  BattleCard = 62
}

@Injectable({
  providedIn: 'root'
})
export class UiService {
  subChilds = 16;
  controlIdentifier = 'projectControlOfContainer';
 
  constructor(
    private graphQLService: GraphQLService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private entityService: EntityService,
    private graphqlqueryService: GraphqlqueryService,
    private authService: AuthService,
    private axivasTranslateService: AxivasTranslateService,
    private externaldatasourceService: ExternaldatasourceService
  ) {

  }

  getControlTypeArray() {
    const array = [];
    const stringKeys = Object
    .keys(ControlType)
    .filter((v) => isNaN(Number(v)))

    stringKeys.forEach((key, index) => {
      array.push({ id: index, value: key});
    });
    array.sort((a, b) => a.value < b.value ? -1 : 1);
    return array;
  }

  updateControlCache(): Promise<any> {
    return new Promise((updateControlCacheResolve, updateControlCacheReject) => {
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe(
        result => { updateControlCacheResolve(result); }),
        error => { updateControlCacheReject(error); }
    });
  }

  getLabelClasses(controlDefinition) {    
    let returnValue = 'customTextControlWrapper__label ';
    if (controlDefinition.additionalCssClassesMainLabel) {
      returnValue = returnValue.concat(' ', controlDefinition.additionalCssClassesMainLabel);
    }
    return returnValue;
  }

  updateControlInList(control: any) {
    if (control) {
      if (this.applicationInfoService.controls.ContainsKey(control.logicalControlId)) {
        this.applicationInfoService.controls.Set(control.logicalControlId, control);
        this.applicationInfoService.controlsBackUp.Set(control.logicalControlId, JSON.parse(JSON.stringify(control)));
      } else {
        this.applicationInfoService.controls.Add(control.logicalControlId, control);
        this.applicationInfoService.controlsBackUp.Add(control.logicalControlId, JSON.parse(JSON.stringify(control)));
      }
    }
  }

  getContainerHeightEx(container, value, mobileHeight = null) {
    let number = Number(this.getDesignSpanPosition(container, value));
    if (this.applicationInfoService.isMobile() && mobileHeight != null) {
      return mobileHeight;
    }
    return number;    
  }


  checkViewModeVisibility(controlDefinition): boolean {
    let returnValue = true;
    switch (controlDefinition.lookupVisibilityId) {
      case null: 
        break;
      case 3038:
        if (!this.applicationInfoService.isMobile()) {
          returnValue = false;
        }
        break;
      case 3039:
        if (this.applicationInfoService.isMobile()) {
          returnValue = false;
        }
        break;
    }
    return returnValue;
  }

  getControlSettingOrDefault(control, setting, defaultValue) {
    return this.commonService.getControlSettingOrDefault(control, setting, defaultValue);
  }

  public getControlDefinitions(controlId: string): string {
    // console.warn('getControlDefinitions', this.applicationInfoService.controls.Item(controlId));
    if (this.applicationInfoService.controls.ContainsKey(controlId)) {
      return this.applicationInfoService.controls.Item(controlId);
    } else {
      return null;
    }
  }

  public getControls(controlId: number): Promise<any> {
    // this.loaderService.display(true);    
    return new Promise((resolve, reject) => {
      if (this.applicationInfoService.projectID == 0) { resolve([]); return; }
      // this.updateControlCache().then(() => {
        let idArray: any[] = [];
        const promiseArray: Promise<any>[] = [];
        let getControlsQuery = this.createControlString(this.subChilds); // this.controlQuery;
        if (controlId === -1) { resolve('test'); }

        getControlsQuery = this.commonService.modifyQueryStringWithApplicationInfos(getControlsQuery);
        getControlsQuery = getControlsQuery.replace('<controlId>', controlId.toString());
        this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, getControlsQuery)
        .then(result => {
          let baseString = result.data[this.controlIdentifier];
          baseString.sort((a, b) => a.order < b.order ? -1 : 1);
          idArray = this.getControlIdArray(result);          
          idArray.forEach(id => {
            promiseArray.push(this.getControlDetails(id));
          });
          // console.warn('getControls promiseArray', controlId, promiseArray);
          Promise.all(promiseArray).then(() => {
            idArray.forEach(id => {
              baseString = JSON.stringify(baseString);
              let newResult = JSON.stringify( this.applicationInfoService.controls.Item(id));
              newResult = newResult.slice(0, -1);
              newResult = newResult.substr(1);
              baseString = baseString.replace('"logicalControlId":' + id + ',', newResult + ',');
              baseString = JSON.parse(baseString);
            })
            resolve(baseString);
          })
          .catch(error => {
            // this.loaderService.display(false);
            reject(error); });
        }).catch(error => {
          // this.loaderService.display(false);
          reject(error);
        });
      });
    //});
  }

  getQueryFieldsFromListControl(control): string {
    let queryString = ' ';
    if (control) {
      if (control.childs) {
        control.childs.forEach(controlChild => {          
          if (controlChild.childs) {
            controlChild.childs.forEach(subControlChild => {
              if ((subControlChild.value) && controlChild.isActive) {
                queryString = queryString.concat(this.getQueryFieldsFromListControlDeserialize(subControlChild.value), ' ');
              }
            });
          }
        });
      }
    }
    return queryString;
  }

  getQueryFieldsFromListControlDeserialize(baseString: any): string {
    let resultString = '';
    const itemArray = baseString.split(';');
    itemArray.forEach(queryItem => {
      if (!queryItem.includes('.')) {
        resultString = resultString.concat(queryItem, ' ');
      } else {
        let subLevelCount = 0;
        let deserializedString = '';
        if (queryItem.includes('.')) {
          // TODO: Mehr als x Ebenen supporten
          const layers = queryItem.split('.');
          const maxIndex = layers.length - 1;
          layers.forEach((layer, index) => {
            if (index === maxIndex) {
              deserializedString = deserializedString.concat(layer);
            } else {
              deserializedString = deserializedString.concat(layer, ' {');
              subLevelCount ++;
            }
          });
        }

        while (subLevelCount > 0) {
          deserializedString = deserializedString.concat(' }');
          subLevelCount --;
        }
        resultString = resultString.concat(deserializedString, ' ');
      }
    });
    return resultString;
  }

  getControlsForProject(): Promise<any> {
    return new Promise((getControlsForProjectResolve, getControlsForProjectReject) => {
      let getControlsForProjectQuery = this.graphqlqueryService.uiServiceControlQueryTemplate.replace(/<uiServiceControlBaseString>/g, this.graphqlqueryService.uiServiceControlBaseString);
      getControlsForProjectQuery = this.commonService.modifyQueryStringWithApplicationInfos(getControlsForProjectQuery);
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query, getControlsForProjectQuery, [])
      .then(getControlsForProjectResult => {
        this.consumeControls(getControlsForProjectResult);        
        getControlsForProjectResolve(getControlsForProjectResult);
      });
    });
  }

  consumeControls(controlList) {
    const controlArray = [];          
    controlList.data.projectControlArray.forEach(control => {
      const controlObject = JSON.parse(JSON.stringify(controlList.data.projectControlArray
          .find(controlToCheck => controlToCheck.id == control.id ))); 
      if (control.childs) {
        controlObject['childs'] = [];
        control.childs.forEach(child => {
          const childObject = controlList.data.projectControlArray
            .find(controlToCheck => controlToCheck.id == child.id )                
          if (child.childs) {
            childObject['childs'] = [];
            child.childs.forEach(subChild => {
              const subChildObject = controlList.data.projectControlArray
                .find(controlToCheck => controlToCheck.id == subChild.id )
              childObject['childs'].push(subChildObject)
              subChildObject['childs'] = [];
            });
          }
          controlObject['childs'].push(childObject);
        });
      }
      controlArray.push(controlObject);            
    });  
    controlArray.forEach(control => {
      this.sortControlChilds(control);
      this.updateControlInList(control);
    });
  }

  getControlIdArray(base: any): any[] {
    let idArray: any[] = [];
    const controlArray = base.data[this.controlIdentifier];
    controlArray.sort((a, b) => a.order < b.order ? -1 : 1);
    controlArray.forEach(element => {
      idArray = this.getControlId(idArray, element);
    });
    return idArray;
  }

  setControlFocus(controlId: any) {
    const element = document.getElementById(controlId);
    if (element) {
      element.focus();
    }
  }

  getControlId(idArray: any[], control: any): any[] {
    idArray.push(control.logicalControlId);    
    if (control.childs == null) { control.childs = []; }
    control.childs.forEach(element => {
      idArray = this.getControlId(idArray, element);
    });
    return idArray;
  }

  createControlString(subChilds: number): string {
    let returnValue = '';
    for (let i = 0; i < subChilds; i++) {
      returnValue = returnValue.concat(` childs {` + this.graphqlqueryService.uiServiceControlBaseQuery);
    }
    for (let i = 0; i < subChilds; i++) {
      returnValue = returnValue.concat(`}`);
   }
   returnValue = this.graphqlqueryService.uiServiceControlQuery.replace('<subChilds>', returnValue);
   return returnValue;
  }

  getControlDetails(controlId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.applicationInfoService.controls.ContainsKey(controlId)) {
        resolve(this.applicationInfoService.controls.Item(controlId));
        return;
      } else {
        reject ('controlId not found: ' + controlId);
      }
      if (controlId === null) {
        reject ('no controlID');
      }
    });
  }

  sortControlChilds(control: any) {
    this.loaderService.display(true);
    this.cleanControl(control);
    this.sortControlContent(control);    
    if (control.childs.length > 0) {
      control.childs.sort((a, b) => a.order < b.order ? -1 : 1);
      control.childs.forEach(controlChild => {
        this.cleanControl(controlChild);
        this.sortControlContent(controlChild);
        if (controlChild.childs) {
          if (controlChild.childs.length > 0) {
            this.cleanControl(controlChild);
            this.sortControlChilds(controlChild);
          }
        }
      });
    }
    this.loaderService.display(false);
  }

  cleanControlParameters(method) {
    if (method.parameters == null) { method.parameters = []; }
    if (method.childs == null) { method.childs = []; }
    if (method.conditions == null) { method.conditions = []; }  
    if (method.controls == null) { method.controls = []; }  
  }

  cleanControl(control) {
    if (control.childs == null) { control.childs = []; }
    if (control.controlAttributeModifierGroups == null) { control.controlAttributeModifierGroups = []; }
    if (control.uiControlExternalDataSources == null) { control.uiControlExternalDataSources = []; }
    if (control.attributeModifiers == null) { control.attributeModifiers = []; }
    if (control.methods == null) { control.methods = []; }
    if (control.uiControlSecurityPermissions == null) { control.uiControlSecurityPermissions = []; }
  }

  sortControlContent(control: any) {
    if (control === undefined) { return; }
    if (control.methods.length > 0) {
      control.methods.sort((a, b) => a.order < b.order ? -1 : 1);
      control.controlAttributeModifierGroups.sort((a, b) => a.order < b.order ? -1 : 1);
      control.attributeModifiers.sort((a, b) => a.order < b.order ? -1 : 1);
      control.methods.forEach(method => {
        this.cleanControlParameters(method);
        if (method.parameters.length > 0) {
          // console.error('sortControlContent', control.id, method.parameters);
          method.parameters.sort((a, b) => a.order < b.order ? -1 : 1);
        }
      });
    }
  }

  public getUIfromID(controlID: string, initalID): Promise<any> {
    return new Promise((getUIfromIDResolve, getUIfromIDReject) => {
      if (!this.applicationInfoService.projectLoaded) {
        return;
      }
      this.eventService.navigationInitialized(controlID);
      if (this.applicationInfoService.containerData.ContainsKey(controlID)) {
        getUIfromIDResolve(this.applicationInfoService.containerData.Item(controlID));
        this.eventService.showNewContent(controlID, this.applicationInfoService.containerData.Item(controlID), initalID);
        return;
      }
      if (!isNaN(Number(controlID))) {
        this.getControls(Number(controlID))
        .then(GetControlsResult => {
          let returnValue = '';
          let previousInlineValue = 0;
          let inlineBlockOpen = false;
          GetControlsResult.forEach(element => {
            if (previousInlineValue !== element.inline) {
              if (inlineBlockOpen) {
                returnValue = returnValue + '</div>';
                inlineBlockOpen = false;
              }
              returnValue = returnValue + '<div></div>';
              if (element.inline !== 0) {
                // Inline-Block is required for these controls
                inlineBlockOpen = true;
                if (element.controlType === 0 && (element.inline === '200')) {
                  returnValue = returnValue + '<div class="controlInlineBlockContainer">';  
                } else {
                  returnValue = returnValue + '<div class="controlInlineBlock">';
                }                
              }
            }
            if (element.controlType === 0) {              
              returnValue = returnValue + '<jj-container controluiid=' + element.logicalControlId + '></jj-container>';
            } else {
              returnValue = returnValue + '<jj-universal controlid=' + element.logicalControlId + '></jj-universal>';
            }
            previousInlineValue = element.inline;
          });
          this.eventService.showNewContent(controlID, returnValue, initalID);
          this.applicationInfoService.containerData.Add(controlID, returnValue);
          this.getControlDefinitions(controlID);
          if (inlineBlockOpen) {
            returnValue = returnValue + '</div>';
          }
          getUIfromIDResolve(returnValue);
          return returnValue;
        }).catch(() => {
          getUIfromIDReject();
        });
      } else {
        switch (controlID.toLowerCase()) {
          case 'jjsection':
            this.eventService.showNewContent(controlID, '', initalID);
            getUIfromIDResolve('');
            return;
          case 'uteststartseite':
            return `Startseite<br><br>
            Hier werden alle relevanten Neuigkeiten angezeigt
            `;
          case 'dashboard':
            this.eventService.showNewContent(controlID, '<jj-container controluiid="1200">', initalID);
            getUIfromIDResolve('<jj-container controluiid="1200">');
            return '<jj-container controluiid="1200">';
          default:
            this.eventService.showNewContent(controlID, '<jj-' + controlID + '></jj-' + controlID + '>', initalID);
            getUIfromIDResolve('<jj-' + controlID + '></jj-' + controlID + '>');
            return '<jj-' + controlID + '></jj-' + controlID + '>';
        }
      }
    });
  }

  updateControlValueDict(controlID: string, value: any) {
    if (this.applicationInfoService.controlValues.ContainsKey(controlID)) {
      this.applicationInfoService.controlValues.Set(controlID, value);
    } else {
      this.applicationInfoService.controlValues.Add(controlID, value);
    }
  }

  getExternalDatasourceFromControlEntityMember(control: any) {
    if (!control) {
      return null;
    }
    let getExternalDatasourceFromControlEntityMemberResult = null;
    if (control.uiEntityInstanceId) {
      let compareValue = control.value;
      if (!this.commonService.isNullOrUndefined(control.value)) {
        const valueLayers = control.value.split('.');
        if (valueLayers.length > 1) {
          compareValue = valueLayers[valueLayers.length - 1];
        }
      }
      if (this.applicationInfoService.entities.Item(control.uiEntityInstanceId)) {
        this.applicationInfoService.entities.Item(control.uiEntityInstanceId).entityMembers.forEach(entityMember => {
          if (compareValue === entityMember.defaultName) {
            if (entityMember.externalDataSource) {
              getExternalDatasourceFromControlEntityMemberResult = entityMember.externalDataSource;
            }
          }
        });
      }
    }
    return getExternalDatasourceFromControlEntityMemberResult;
  }

  getEntityMemberLookupTable(control: any) {
    if (!control) {
      return null;
    }
    let getEntityMemberLookupTableResult = null;
    if (control.uiEntityInstanceId) {
      let compareValue = control.value;
      if (!this.commonService.isNullOrUndefined(control.value)) {
        const valueLayers = control.value.split('.');
        if (valueLayers.length > 1) {
          compareValue = valueLayers[valueLayers.length - 1];
        }
      }
      if (this.applicationInfoService.entities.Item(control.uiEntityInstanceId)) {
        this.applicationInfoService.entities.Item(control.uiEntityInstanceId).entityMembers.forEach(entityMember => {
          if (compareValue === entityMember.defaultName) {
            if (!this.commonService.isNullOrUndefined(entityMember.lookupTableId)) {
              getEntityMemberLookupTableResult = { 'lookupTableId': entityMember.lookupTableId,
              'lookupTableSource': entityMember.lookupTableSource };
            }
          }
        });
      }
    }
    return getEntityMemberLookupTableResult;
  }

  setControlHeight(controlDefinition: any, modifier: number = 0) {
    return {
      'height': controlDefinition.height
    };
  }

  getBorderWidthOfElement(elementId: any): number {
    const controlElement = document.getElementById(elementId);
    if (controlElement) {
      const element = window.getComputedStyle(document.getElementById(elementId), null);
      let borderWidth = Number(this.getControlDimensionWithoutAttribute(element.borderWidth));
      if (isNaN(borderWidth)) {
        borderWidth = 0;
      }
      return Number(borderWidth);
    } else {
      return 0;
    }
  }

  setControlWidth(controlDefinition: any, modifier: number = 0) {
    const baseValue: string = controlDefinition.width;
    if (baseValue.includes('%')) { return; }
    return { 'width': '100%' };
  }

  getDesignSpanPosition(elementId: string, variance: number = 0, bottomItem = null) {
    let topOfNextItem = 0;
    if (bottomItem) {
       topOfNextItem = this.getAbsoluteBottomOfElement(document.getElementById(bottomItem), 0);        
    }

    if ((document.getElementById(elementId))) {
      const designPosition = this.getAbsoluteBottomOfElement(document.getElementById(elementId), 0);
      
      let finalHeight = window.innerHeight - designPosition - variance;
      let parentElement = document.getElementById(elementId).parentElement;
      if(parentElement) {
        while (!this.commonService.isNullOrUndefined(parentElement)) {
          if (this.commonService.checkIfStringContainsString(parentElement.getAttribute('class'), 'ui-dialog-content')) {
            finalHeight = parentElement.getBoundingClientRect().height - designPosition - variance + 100;
            break;
          }
          parentElement = parentElement.parentElement;
        }
      }
      if (bottomItem) {
        finalHeight = topOfNextItem - finalHeight;
      }      
      return finalHeight;
    }
  }

  getDesignSpanPositionMobile(elementId: string, variance: number = 0) {
    if (document.getElementById(elementId)) {
      const designPosition = this.getAbsoluteBottomOfElement(document.getElementById(elementId), 0);
      let finalHeight = this.applicationInfoService.innerHeight - designPosition - variance;
      
      return finalHeight;
    }
  }

  getDomControlWidth(elementId: string, substractValue = 0) {
    const domControl = document.getElementById(elementId);
    if (domControl) {
      let controlWidth = domControl.offsetWidth;
      if (controlWidth > substractValue) {
        controlWidth = controlWidth - substractValue;
      }
      return controlWidth;
    }
    return 0;
  }

  getAbsoluteBottomOfElement(elementToCheck: any, bottomPosition: any) {
    if(elementToCheck) {
      bottomPosition += elementToCheck.getBoundingClientRect().top;
      bottomPosition += elementToCheck.getBoundingClientRect().height;
      return bottomPosition;
    }
    return 0;
  }

  getControlDimensionWithoutAttribute(source: string) {
    if (source) {
      return source.replace(/\D/g, '');
    }
  }

  setControlStyle(type: string, modifier: number = 0, controlDefinition: any) {
    switch (type) {
      case 'whitespace':
        if (this.applicationInfoService.isMobile()) {
          return {
            'padding-left': 0 + 'px'
          };
        } else {
          return {
            'padding-left': controlDefinition.reserverSpaceLeft + 'px'
          };
        }
      case 'label':
        return { 'width': controlDefinition.displayTextWidth, height: controlDefinition.displayTextHeight };

      case 'control':
      const innerControlHeight = controlDefinition.height;
      return {
        'width': '100%',
        'height': innerControlHeight,
      };
    }
  }

  checkAttributeModifierGroups(attributeToCheck, controlDefinition, returnValue) {
    let attributeChecked = false;
    if (controlDefinition === null) {
      return attributeChecked;
    }
    if (!this.commonService.isNullOrUndefined(controlDefinition.controlAttributeModifierGroups)) {
      controlDefinition.controlAttributeModifierGroups.forEach(controlAttributeModifierGroup => {
        let controlAttributeModifierGroupFoundResult = null;
        if ((controlAttributeModifierGroup.attribute === attributeToCheck) && !attributeChecked) {
          attributeChecked = true;
          let validCounter = 0;
          controlAttributeModifierGroup.controlAttributeModifiers.forEach(controlAttributeModifier => {
            let modifierIsValid = false;
            if (!this.commonService.isNullOrUndefined(controlAttributeModifier.compareValue)) {
              const compareValue = controlAttributeModifier.compareValue.split(';');
              if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(compareValue[0]))) {
                return returnValue;
              }
              let value = this.applicationInfoService.entities.Item(compareValue[0]).data
                [this.applicationInfoService.entityMember.Item(compareValue[1]).defaultName];
              if (isUndefined(value)) { value = null; }
              if (!this.commonService.isNullOrUndefined(value)) { value = value.toString(); }
              switch (controlAttributeModifier.compareType) {
                case '=':
                  if (value === controlAttributeModifier.refValue) { modifierIsValid = true; }
                  break;
                case '!=':
                  if (value !== controlAttributeModifier.refValue) { modifierIsValid = true; }
                  break;
                case 'LIKE':
                  if ((!this.commonService.isNullOrUndefined(value)
                    && !this.commonService.isNullOrUndefined(controlAttributeModifier.refValue))) {
                    if (this.commonService.checkIfStringContainsString(value.toUpperCase(),
                      controlAttributeModifier.refValue.toUpperCase())) {
                      modifierIsValid = true;
                    }
                  }
                  if (value === controlAttributeModifier.refValue) { modifierIsValid = true; }
                  break;
                case 'STARTSWITH':
                  if ((!this.commonService.isNullOrUndefined(value) &&
                    !this.commonService.isNullOrUndefined(controlAttributeModifier.refValue))) {
                    if (this.commonService.checkIfStringStartsWith(value, controlAttributeModifier.refValue)) {
                      modifierIsValid = true;
                    }
                  }
                  break;
              }

              if (modifierIsValid) {
                if (controlAttributeModifierGroup.connection === 'or') {
                  controlAttributeModifierGroupFoundResult = false;
                }
                if (controlAttributeModifierGroup.connection === 'and') {
                  validCounter ++;
                }
              }
            }
          });
          if (validCounter === controlAttributeModifierGroup.controlAttributeModifiers.length) {
            controlAttributeModifierGroupFoundResult = false;
          }
        }
        if (controlAttributeModifierGroupFoundResult === false) {
          returnValue = false;
        } else {
          attributeChecked = false;
        }
      });
    }
    return returnValue;
  }

  checkAttributeChanges(attributeToCheck: any, controlDefinition: any, originalValue: any, type: VariableType): any {
    let result: any = null;
    let compareValue: any = null;
    if (!controlDefinition) {
      return;
    }
    if (this.commonService.isNullOrUndefined(controlDefinition.attributeModifiers)) {
      controlDefinition.attributeModifiers = [];
    }
    if (controlDefinition.attributeModifiers.length === 0) {
      return originalValue;
    }
    controlDefinition.attributeModifiers.forEach(modifier => {      
      if (result == null && this.commonService.isNullOrUndefined(modifier.attributeModifierGroup)) {
        if (modifier.attribute.toLowerCase() === attributeToCheck) {
          compareValue = this.commonService.getCompareValue(modifier, compareValue);
          result = this.commonService.compare(modifier, compareValue, result);
        }
      }
    });
    if (result == null) { result = originalValue; }
    if (originalValue !== result) {
      switch (type) {
        case VariableType.Boolean:
        result = JSON.parse(result);
        break;
        case VariableType.Date:
        result = new Date(result);
        break;
      }
    }
    return result;
  }

  getToolTip(controlDefinition: any): string {
    const returnValue = this.commonService.nullOrUndefinedToDefaultValue(controlDefinition.tooltip, '');
    if (returnValue === '') {
      return this.getDisplayText(controlDefinition);
    } else {
      return returnValue;
    }
  }

  getDisplayText(controlDefinition: any) {
    if (!controlDefinition) {
      return '';
    }
    if (controlDefinition.nameTranslationTokenId != null) {
      return this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(controlDefinition, 'displayText'));
    }
    if (controlDefinition.displayText) {
      return controlDefinition.displayText;
    } else {
      if (controlDefinition.uiEntityInstanceId) {
        const member = this.entityService.getEntityMember(
            this.applicationInfoService.entities.Item(controlDefinition.uiEntityInstanceId), controlDefinition.value);
        if (member) {
          if (member.nameTranslationToken) {
            return member.nameTranslationToken.tokenFullName;
          } else {
            return member.defaultName;
          }
        }
      }
    }
  }

  createDefaultControl(parentControl) {

  }

  deleteSubControl(control): Promise<any> {
    const promiseArrayAttributeModifier: Promise<any>[] = [];
    return new Promise((deleteControlResolve, deleteControlReject) => {
      control.parentContainerId = null;
      control.parentControlId = null;
      this.saveControl(control).then(() => {
        deleteControlResolve(null);
      }).catch(() => {
        deleteControlReject(null);
      });
      // control.attributeModifiers.forEach(modifier => {
      //   promiseArrayAttributeModifier.push(this.externaldatasourceService.executeExternalDataSource(254, [modifier.id]));
      // });
      // Promise.all(promiseArrayAttributeModifier).then(() => {
      //   this.externaldatasourceService.executeExternalDataSource(203, [control.id])
      //   .then(deleteControlResult => {
      //     deleteControlResolve(deleteControlResult);
      //   })
      //   .catch(error => {
      //     deleteControlReject(error);
      //   });  
      // });
    });
  }

  saveControl(control): Promise<any> {
    return new Promise((saveControlResolve, saveControlReject) => {
      this.externaldatasourceService.executeExternalDataSource(161, [
        this.commonService.getModifyArrayBody(control, [] )
      ]).then(saveControlResult => {
        saveControlResolve(saveControlResult);
      }).catch(error => {
        saveControlReject(error);
      })
    });
  }

  createControl(control): Promise<any> {
    return new Promise((createControlResolve, createControlReject) => {
      this.externaldatasourceService.executeExternalDataSource(174, [
        this.commonService.getModifyArrayBody(control, [] )
      ]).then(saveControlResult => {
        createControlResolve(saveControlResult);
      }).catch(error => {
        createControlReject(error);
      })
    });
  }
}
