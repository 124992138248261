<span id="newAccountDesignSpan"></span>
<div class="newAccount__wrapper" [style.max-height.px]="getMaximumHeightOfSubContainer('newAccountDesignSpan')">

    <!-- Page 1 -->
    <div *ngIf="page==1" class="newAccount__controls" [innerHTML]="newAccountContainerInnerHtml"></div>    
    <div *ngIf="applicationInfoService?.applicationSettings['addOwnerToNewAccount']=='true' && page==1" class="newAccountSpacer">
        <app-select-input label="Account.Label.AccountOwner" 
            [(ngModel)]="applicationInfoService.entities.Item(newAccountEntityId.toString()).data['ownerId']" 
            [options]="projectUsers" display="userName" bind="id"></app-select-input>       
    </div>
    <div class="newAccountSpacer newAccountKeywords" *ngIf="page==1">        
        <app-text-input label="Account.Label.Keywords" [(ngModel)]="applicationInfoService.entities.Item(newAccountEntityId.toString()).data.keywords"></app-text-input>
        <div class="newAccountKeywords__desc">{{ 'NewAccount.Label.KeywordsDescription' | texttransform}}</div>
    </div>
    
    <!-- Page 2 -->
    <div class="keyWordSearch" *ngIf="page==2">
        <div class="keyWordSearch__name">
            {{ applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data['name1'] }}
        </div>
        <div *ngIf="prospectToConvert != null" class="prospectInfo">
            <div [innerHTML]="'NewAccount.Label.ProspectInfo' | texttransform"></div>
            <div>
                {{ 'NewAccount.Label.KeywordsUsed' | texttransform}}: 
                <app-text-input label="Account.Label.Keywords" (change)="createSearchOrNewAccount()"
                    [(ngModel)]="applicationInfoService.entities.Item(newAccountEntityId.toString()).data.keywords"></app-text-input>
                <!-- <span class="keyWordSpan">
                    {{ applicationInfoService.miscSettings['entityTableKeywords'] }}
                </span>     -->
            </div>
        </div>    
    </div>
    <div *ngIf="(prospectToConvert == null || prospectToConvert == undefined) && page==2" class="newAccount__search" [ngClass]="{ hideInDom: accountsSearched == false }">
        <div [innerHTML]="newAccountContainerListInnerHtml" class="maxHeight"></div>
    </div>    
    <div *ngIf="prospectToConvert != null && prospectToConvert != undefined && page==2" class="newAccount__search" [ngClass]="{ hideInDom: accountsSearched == false }">
        <div [innerHTML]="newAccountContainerListInnerHtmlProspect" class="maxHeight"></div>
    </div>    
    
    <div class="newAccount__buttons" id="newAccount__buttons" *ngIf="page==1">
        <app-main-button
            *ngIf="accountsSearched == false && searchOption == true" 
            (onClick)="createSearchOrNewAccount();$event.stopPropagation()" 
            [disabled]="poolId == null"
            title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}"
            [label]="newAccountSearchText | texttransform"></app-main-button>
    </div>

    <div class="newAccount__buttons" id="newAccount__buttons" *ngIf="page==2">                  
        <app-main-button (onClick)="backToPageOne()" label="General._.Back"></app-main-button>

        <app-main-button 
            *ngIf="(accountsSearched == true || searchOption == false) && prospectToConvert == null" 
            (onClick)="createNewAccount();$event.stopPropagation()" 
            [disabled]="poolId == null"
            title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}"
            [label]="newAccountText | texttransform "></app-main-button>

        <app-main-button (onClick)="createNewAccount(true);$event.stopPropagation()"
            *ngIf="((assignContactAfterCreation == false && accountsSearched == true) || searchOption == false)  && prospectToConvert == null" 
            [disabled]="poolId == null" 
            title="{{ 'NewAccount.Label.CreateNewAccountAndEnter' | texttransform }}"
            [label]="newAccountEnterText | texttransform"></app-main-button>     
            
        <!-- Prospect -->
        <app-main-button 
            *ngIf="(accountsSearched == true || searchOption == false) && prospectToConvert != null" 
            (onClick)="createNewAccount()" 
            [disabled]="poolId == null"
            type="border"
            title="{{ 'NewAccount.Label.CreateAndConnectToProspect' | texttransform }}"
            label="NewAccount.Label.CreateAndConnectToProspect"></app-main-button>
        <app-main-button 
            *ngIf="(accountsSearched == true || searchOption == false) && prospectToConvert != null" 
            (onClick)="createNewAccount(false, true)" 
            [disabled]="poolId == null"
            type="border"
            title="{{ 'NewAccount.Label.CreateAndConnectToProspect' | texttransform }}"
            label="NewAccount.Label.CreateAndEnrich"></app-main-button>
    </div>

    <!-- Page 3 -->
    <div *ngIf="page==3" class="dataEnrichment">
        <div class="dataEnrichment__label">
            {{ 'NewAccount.Label.DataEnrichmentHeader' | texttransform}}
        </div>
        <app-echobot-interface></app-echobot-interface>
    </div>
</div>