import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, gql, ApolloLink, from } from '@apollo/client/core';
import { environment } from '../../../environments/environment';
import { onError } from 'apollo-link-error';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { MessagingService, MessageSeverity, MessagePosition } from '../../jollyjupiter/service/messaging.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { LoaderService } from './loader-service';
import { AxivasTranslateService } from '../translation/axivas-translate.service';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';

export enum ApolloMethod {
  Query = 'Get',
  Mutation = 'Post',
}

export enum JJApolloClient {
  CentralAPI = 'main',
  DataApi = 'data',
  BackendApi = 'backend',
  Additional = 'additional',
}

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {
  authBearerSent = false;
  customErrorCollection = 'ErrorsCollection:';
  logoutLink: any = null;
  authMiddleware: any = null;
  private httpLink: HttpLink;

  constructor(
    private apollo: Apollo,
    httpLink: HttpLink,
    private applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private loaderService: LoaderService,
    private awsCognitoService: AwsCognitoService,
    private commonService: CommonService
  ) {
    this.httpLink = httpLink;

    this.logoutLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        let specifiedExceptionShown = false;
        const networkErrorHandler: any = networkError;
        if (this.applicationInfoService.applicationInitialized && networkErrorHandler.status == 401) {
          this.awsCognitoService.showAwsLoginPage();
        }
        if (!this.commonService.isNullOrUndefined(networkErrorHandler.error)) {
          if (!this.commonService.isNullOrUndefined(networkErrorHandler.error.errors)) {
            networkErrorHandler.error.errors.forEach(element => {
              // Trial expired
              console.warn('networkErrorHandler', element);
              if (element.message != null) {
                if (element.message.includes('Trial period for this used has expired')) {
                  location.replace(environment.trialexpiredpage);
                }
              }
              if (!this.commonService.isNullOrUndefined(element.message)) {
                if (element.message.lastIndexOf(this.customErrorCollection, 0) === 0) {
                  let object: string = element.message;
                  object = object.substring(this.customErrorCollection.length);
                  const errorObject = JSON.parse(object);
                  if (errorObject.errors) {
                    errorObject.errors.forEach(error => {

                      if (this.applicationInfoService.applicationInitialized) {
                        let errorMessage = '';
                        if (error.DescriptionTokenName) {
                          errorMessage = this.axivasTranslateService.getTranslationTextForToken(error.DescriptionTokenName);
                        }
                        if (error.DescriptionParameters) {
                          error.DescriptionParameters.forEach((parameter, index) => {
                            errorMessage = errorMessage.replace('{' + index + '}', parameter);
                          });
                        }
                        const detailText = error.DescriptionTokenName == null ? error.Description : errorMessage;
                        if (detailText.length > 100) {
                          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, 'GraphQL Warning', '', false );
                        } else {
                          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, 'GraphQL Warning', detailText, false );
                        }
                        console.warn('GraphQL Warning', detailText)
                        specifiedExceptionShown = true;
                      }
                    });
                  }
                } else {
                  if (this.applicationInfoService.applicationInitialized) {
                    console.warn('graphql error element', element.Message);
                    if (element.message == 'You are not authorized to run this query ().') {
                      this.awsCognitoService.showAwsLoginPage();
                    }
                    if (element.InnerException) {
                      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, 'GraphQL error', '', false );
                      console.error('GraphQL error', element.InnerException.Message);
                    } else {
                      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, 'GraphQL error', '', false );
                      console.error('GraphQL error', element.message);
                    }
                    specifiedExceptionShown = true;
                  }
                }
              }
            });
          }
        }
        if (specifiedExceptionShown === false && this.applicationInfoService.applicationInitialized) {
          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, 'GraphQL NetworkError', '', false );
          console.error('graphql networkError', networkError.message);
        }
      }
      if (graphQLErrors) {
        console.log('ApolloNetworkError', graphQLErrors);
      }
    });

    this.authMiddleware = new ApolloLink((operation, forward) => {
      if (this.authBearerSent === false) {
        this.authBearerSent = true;
      }
      let bearerCode = localStorage.getItem('awstoken')

      let fakeUser = false;
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.user)) {
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
          fakeUser = true;
        }
      }
        if (fakeUser && !this.applicationInfoService.ignoreFakeUserId) {
          operation.setContext(context => ({
            ...context,
            headers: {
              ...context.headers,
              'ProjectId': '' + this.applicationInfoService.projectID,
              'authorization': 'bearer ' + bearerCode,
              'UserId': '' + this.applicationInfoService.user.fakeUserId,
              'RoleId': '' + this.applicationInfoService.currentUserRoleId,
              'RequestIp': '' + this.applicationInfoService.clientIP
            }
          }));
        } else {
          operation.setContext(context => ({
            ...context,
            headers: {
              ...context.headers,
              'ProjectId': '' + this.applicationInfoService.projectID,
              'authorization': 'bearer ' + bearerCode,
              'UserId': '' + this.applicationInfoService.userID,
              'RoleId': '' + this.applicationInfoService.currentUserRoleId,
              'RequestIp': '' + this.applicationInfoService.clientIP
            }
          }));
        }
      return forward(operation);
    });
    this.inititalizeDataEndpoint(environment.graphQlUrl , 'main');
  }

  public inititalizeDataEndpoint(dataEndpoint: string, dataEndpointName: string): Promise<any> {
    return new Promise((inititalizeDataEndpointResolve, inititalizeDataEndpointReject) => {
      // console.log('endpoint preparing: ', dataEndpointName, ' url: ', dataEndpoint );
      if (this.applicationInfoService.endpoints.ContainsKey(dataEndpointName)) {
        if (this.applicationInfoService.isDeveloper) {
          console.log('endpoint was already established: ', dataEndpointName, ' url: ', dataEndpoint );
        }
        inititalizeDataEndpointResolve(true);
        return;
      }
      dataEndpoint = dataEndpoint.replace('{dataEndpoint}', dataEndpoint);
      const graphQLDataLink = this.httpLink.create({ uri: dataEndpoint });
      this.apollo.create({
        link: from([this.logoutLink, this.authMiddleware, graphQLDataLink])
        ,
        cache: new InMemoryCache({
          addTypename: false
        })
        ,
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache'
          }
        }
      }, dataEndpointName);
      if (this.applicationInfoService.isDeveloper) {
        console.log('endpoint established: ', dataEndpointName, ' url: ', dataEndpoint, this.apollo );
      }
      this.applicationInfoService.endpoints.Add(dataEndpointName, dataEndpoint);
      inititalizeDataEndpointResolve(true);
    });
  }

  public apolloGQLpromiseWithParameter(client: string, method: ApolloMethod, paramQuery: string,
    parameterToReplaceVariables: any[], paramVariable?: any): Promise<any> {
    return new Promise((apolloGQLpromiseWithParameterResolve, apolloGQLpromiseWithParameterReject) => {
      parameterToReplaceVariables.forEach((param, counter) => { paramQuery = paramQuery.replace('<' + counter + '>', param); });
      this.apolloGQLpromise(client, method, paramQuery, paramVariable)
      .then(apolloGQLpromiseWithParameterResult => {
        apolloGQLpromiseWithParameterResolve(apolloGQLpromiseWithParameterResult);
      })
      .catch(error => {
        apolloGQLpromiseWithParameterReject(error);
      });
    });
  }

  public apolloGQLpromise(client: string, method: ApolloMethod, paramQuery: string, paramVariable?: any): Promise<any> {
    return new Promise((apolloGQLpromiseResolve, apolloGQLpromiseReject) => {
      if (client.toLowerCase() === 'data') { client = 'dataEndPoint' + this.applicationInfoService.projectID; }
      if (!paramVariable) {
        paramVariable = '';
      } else {
        paramVariable = JSON.parse(paramVariable);
      }
      switch (method) {
        case ApolloMethod.Query:
          paramQuery = this.commonService.modifyQueryStringWithApplicationInfos(paramQuery);
          this.apollo.use(client).query({ query: gql(paramQuery), variables: paramVariable })
          .subscribe(
            result => {
              apolloGQLpromiseResolve(result);
            }, (error) => {
              apolloGQLpromiseReject(error);
            });
        break;

      case ApolloMethod.Mutation:
        paramQuery = this.commonService.modifyQueryStringWithApplicationInfos(paramQuery);
         this.apollo.use(client).mutate({ mutation: gql(paramQuery), variables: paramVariable })
        .subscribe(result  => {
          apolloGQLpromiseResolve(result);
        }
        , (error) => {
          apolloGQLpromiseReject(error);
        });
        break;
      }
    });
  }
}
